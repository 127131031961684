export const strings = { 
    CMS_MENU_HOW: 'menu.how',
    CMS_MENU_FIELDS: 'menu.fields',
    CMS_MENU_GIFT_CARDS: 'menu.gift_cards',
    CMS_MENU_START: 'menu.start',
    CMS_LOGIN: 'login',
    CMS_REGISTER: 'register',
    CMS_MENU_PROFILE: 'menu.profile',
    CMS_MENU_FAQS: 'menu.faqs',
    CMS_MENU_DOGS: 'menu.dogs',
} as const;

export type CmsStringKey = keyof typeof strings;
export type CmsStringValue = typeof strings[CmsStringKey];
