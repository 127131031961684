import ReactMarkdown from 'react-markdown';
import { usePage } from '@inertiajs/react';
import { PageProps } from '@/types';

export function useCmsString(slug: string, fallback?: string): JSX.Element | null {
    const { cms } = usePage<PageProps>().props;
    const text = cms?.strings?.find(t => t.slug === slug);
    if (!text) {
        console.warn(`Text with slug "${slug}" not found`);

        if (fallback) {
            return <>{fallback}</>;
        }
        
        return null;
    }

    return (
        <ReactMarkdown>
            {text.content}
        </ReactMarkdown>
    );
}
